<template>
  <v-container
    fluid
    fill-height
    :class="$style.auth"
    class="d-flex justify-center align-start px-1 py-1"
  >
    <v-overlay :value="processing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row dense>
      <v-col cols="12">
        <MyForm :formKey="formKey" :entity="entity" :input="input" ref="form">
          <FieldGenerator
            v-for="(field, index) in fields"
            v-model="input[field.name]"
            :key="index"
            :type="field.type"
            :name="field.name"
            :label="field.label"
            :optionType="field.optionType"
            :selects="field.selects"
            :checkedIds="field.checkedIds"
            :textKey="field.textKey"
            :valueKey="field.valueKey"
            :multiple="field.multiple"
            :limit="field.limit"
            :rules="field.rules"
            :width="field.width"
            :remarks="field.remarks"
            :accept="field.accept"
            :unit="field.unit"
            :placeholder="field.placeholder"
            :hide-details="field.hideDetails"
            :labelVerticalAlign="field.labelVerticalAlign"
          >
            <template v-if="field.type === 'custom'" :slot="field.name">
              <slot
                :name="field.name"
                :input.sync="input[field.name]"
                :error-messages="errorMessages[field.name]"
              />
            </template>
          </FieldGenerator>
        </MyForm>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MyForm from "@/entries/front/components/form";
import ErrorList from "@/entries/front/components/error/error-list";
import FieldGenerator from "@/entries/front/components/field/generator";

export default {
  components: {
    MyForm,
    ErrorList,
    FieldGenerator
  },
  data() {
    return {
      formKey: "signup-userinfo",
      input: {
      },
      fields: [
        {
          label: "性",
          name: "lastName",
          rules: "required|max:32",
          width: "100%",
          placeholder: "山田",
          hideDetails: true,
          labelVerticalAlign: "align-center"
        },
        {
          label: "名",
          name: "firstName",
          rules: "required|max:32",
          width: "100%",
          placeholder: "太郎",
          hideDetails: true,
          labelVerticalAlign: "align-center"
        },
        {
          label: "メールアドレス",
          name: "mailAddress",
          rules: "required|max:32",
          width: "100%",
          placeholder: "account@jam-id.jp",
          hideDetails: true,
          labelVerticalAlign: "align-center"
        },
        {
          label: "パスワード",
          name: "password",
          rules: "required",
          type: "password",
          width: "100%",
          hideDetails: true,
          labelVerticalAlign: "align-center"
        },
      ]
    };
  },
  computed: {
    isError() {
      return this.$store.getters["auth/isError"];
    },
    processing() {
      return this.$store.getters[`auth/processing`];
    },
    entity() {
      return this.$store.getters[`auth/entity`];
    },
    errorMessages() {
      return this.$store.getters[`auth/errorMessages`];
    }
  },
  methods: {
    popstate() {
      window.history.pushState(null, null, null);
      return;
    }
  },
  created() {
    this.$store.commit(`auth/initialize`);

    this.$store.commit(`appBar/entity/setButtons`, {
      next: {
        apiUrl: "",
        pathName: "signup-terms-of-service",
        show: true
      },
      back: {
        apiUrl: "",
        pathName: "signin",
        show: true
      }
    })
  },
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
    this.$store.commit("form/changeDirty", [
      {
        key: this.formKey,
        isDirty: !!this.mailAddress
      }
    ]);
  }
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";

.form {
  background-color: #ffffff;
  width: 100%;
  min-width: 300px;
  padding: 15px 30px;
}

.error {
  color: #ff1100;
  text-align: center;
  font-size: 12px;
}

.reissue-link {
  font-size: 16px;
  text-decoration-line: none;
  color: $base-font-color !important;
}
</style>
